
  import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
  import { Location, Route } from 'vue-router'
  import { LinkGroup } from '@/store/app/state'
  import { Role } from '@/store/user/models'
  import { mapGetters, mapMutations } from 'vuex'

@Component({
  methods: {
    ...mapMutations('app', ['setRedirected']),
  },
  computed: {
    ...mapGetters('app', ['redirect']),
  },
})
  export default class ListGroup extends Vue {
  @Prop({ type: Array, default: () => [] }) items!: Array<LinkGroup>
  @Prop({ type: Boolean, default: false }) isMini!: boolean
  @Prop({ type: Array, default: () => [] }) roles!: Array<Role>
  redirect!: boolean
  selected = null
  menu: Record<string, boolean> = {}

  setRedirected!: (payload: boolean) => void

  mounted (): void {
    this.items.forEach(group => {
      this.$set(this.menu, group.groupName, false)
    })
    this.onChangeRoute(this.$route)
  }

  @Watch('$route', { immediate: true, deep: true })
  onChangeRoute (val: Route): void {
    const path = val.path
    let found = false

    this.sortedGroups.forEach((group, groupIndex) => {
      group.items.forEach(item => {
        if (item.route.path === path || item.route.name === val.name) {
          this.selected = groupIndex

          found = true
        }
      })
    })

    if (this.redirect) {
      this.setRole()
    }

    if (!found) {
      this.selected = null
    }
  }

  goTo (val: Location): void {
    const { path, name } = val
    this.$router.push(path || { name }).catch(() => {
    })
  }

  get sortedGroups () {
    return this.items.sort((a, b) => (a.order || 0) - (b.order || 0))
  }

  setRole (): void {
    const role = this.roles[0]

    const group = this.sortedGroups.find(group => group.groupName === role.slug)

    this.setRedirected(false)
    this.goTo(group.items[0].route)
  }
  }
